var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headline"},[_vm._v("Penerimaan")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersPenerimaan,"items":_vm.dataListLaporanPenerimaan,"loading":_vm.listLoading},scopedSlots:_vm._u([{key:"item.kode",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.kode))])]}},{key:"item.uraian",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.uraian)}})]}},{key:"item.val",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_c('b',[_vm._v(_vm._s(_vm._f("formatMoney")(item.val)))])])]}},{key:"item.tgl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.tgl)))])]}}])})],1)],1),_c('div',{staticClass:"headline"},[_vm._v("Belanja")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBeban,"items":_vm.dataListLaporanBeban,"loading":_vm.listLoading},scopedSlots:_vm._u([{key:"item.kode",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.kode))])]}},{key:"item.uraian",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.uraian)}})]}},{key:"item.plannedamt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_c('b',[_vm._v(_vm._s(_vm._f("formatMoney")(item.plannedamt)))])])]}},{key:"item.val",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_c('b',[_vm._v(_vm._s(_vm._f("formatMoney")(item.val)))])])]}},{key:"item.persenrealisasi",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":item.bgcol,"height":"40","value":item.persenrealisasi * 100,"striped":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"subtitle-1"},[_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])])]}}],null,true)})]}},{key:"item.tgl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.tgl)))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }