<template>
  <div>
    
    <v-row>
      <v-col cols="12" >
        <div class="headline">Penerimaan</div>
        <v-data-table
            :headers="headersPenerimaan"
            :items="dataListLaporanPenerimaan"
            class="elevation-1"
            :loading="listLoading"
          >
           <template v-slot:item.kode="{ item }">
            <span> {{ item.kode }}</span>
          </template>
          <template v-slot:item.uraian="{ item }">
            <span v-html="item.uraian" />
          </template>

          <template v-slot:item.val="{ item }">
            <span style="font-size:11px;"><b>{{ item.val | formatMoney }}</b></span>
          </template>
          
          <template v-slot:item.tgl="{ item }">
            <span style="font-size:12px;">{{ item.tgl | formatDate }}</span>
          </template>
        </v-data-table>
        
        </v-col>
    </v-row>
    <div class="headline">Belanja</div>
    <v-row>
      <v-col cols="12" >

        <v-data-table
            :headers="headersBeban"
            :items="dataListLaporanBeban"
            class="elevation-1"
            :loading="listLoading"
          >
           <template v-slot:item.kode="{ item }">
            <span> {{ item.kode }}</span>
          </template>
          <template v-slot:item.uraian="{ item }">
            <span v-html="item.uraian" />
          </template>

          <template v-slot:item.plannedamt="{ item }">
            <span style="font-size:11px;"><b>{{ item.plannedamt | formatMoney }}</b></span>
          </template>
          <template v-slot:item.val="{ item }">
            <span style="font-size:11px;"><b>{{ item.val | formatMoney }}</b></span>
          </template>

          <template v-slot:item.persenrealisasi="{ item }">
            <v-progress-linear
              :color="item.bgcol"
              height="40"
              :value="item.persenrealisasi * 100"
              striped
            >
              <template v-slot="{ value }">
                <span class="subtitle-1"><strong>{{ Math.ceil(value) }}%</strong></span>
              </template>
            </v-progress-linear>
            
          </template>
          
          <template v-slot:item.tgl="{ item }">
            <span style="font-size:12px;">{{ item.tgl | formatDate }}</span>
          </template>
        </v-data-table>
        
        </v-col>
    </v-row>

  </div>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import moment from 'moment'
import accounting from 'accounting'
import { getListBIOSByDate } from '@/api/laporanbios'

export default {
  name: 'laporanBIOS',
  data () {
    return {
        isLoading: false,
        currrole: [],
        dataListLaporanBeban: [],
        dataListLaporanPenerimaan: [],
        dataAkun: [],
        dataListLaporanperMonth: [],
        dataAkunperMonth: [],
        headersBeban: [
          {
          text: 'Kode Akun',
          align: 'start',
          sortable: true,
          value: 'kode',
          width:'60',
          class:'subtitle-1' 
        },
        { text: 'Uraian', value: 'uraian',  width:'50%', align: 'left', class:'subtitle-1' },
        { text: 'Total Pagu', value: 'plannedamt', width:'30%', align: 'right', class:'subtitle-1' },
        { text: 'Nilai', value: 'val', width:'20%', align: 'right', class:'subtitle-1' },
        { text: '(%)', value: 'persenrealisasi', width:'100', align: 'center', class:'subtitle-1' },
        { text: 'Tgl Update', value: 'tgl',  width:'100',  align: 'center', class:'subtitle-1'},
      ],
      headersPenerimaan: [
          {
          text: 'Kode Akun',
          align: 'start',
          sortable: true,
          value: 'kode',
          width:'60',
          class:'subtitle-1' 
        },
        { text: 'Uraian', value: 'uraian',  width:'50%', align: 'left', class:'subtitle-1' },
        { text: 'Nilai', value: 'val', width:'20%', align: 'right', class:'subtitle-1' },
        { text: 'Tgl Update', value: 'tgl',  width:'100',  align: 'center', class:'subtitle-1'},
      ],
      colors: ['blue', 'light-blue', 'deep-purple', 'green', 'light-green', 'red', 'light-red', 'orange'],
      listLoading: false,
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
      return sdate
    },
    formatDatetime: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD/MM/YYYY HH:mm')
      return sdate
    },
    formatMoney: function (value) {
      if (!value) return ''
      var money = accounting.formatMoney(value,"Rp ", 0, ".", ",");
      return money
    },
  },
  created () {
    const vm = this
    // console.log('created laporanbios')
    vm.getLaporanBIOSYearly()
    // vm.getLaporanPerMonth()
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    // console.log('mount laporanbios')
    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)

  },
  methods: {
    getLaporanBIOSYearly () {
        const vm = this
        var startDate = moment().format('YYYY-01-01') // H-1
        var endDate = moment().format('YYYY-MM-DD') // H-1
        // endDate = '2020-09-12'

        vm.dataListLaporanBeban = []
        vm.dataListLaporanPenerimaan = []
        var arrAkunBeban = []
        var arrAkunPenerimaan = []
        vm.listLoading = true
        
        getListBIOSByDate(startDate, endDate).then(response => {
          var data = response.data
          var objData = Object.values(data)
          console.log('getListBIOSByDate ', objData)
 
          if(objData){
            objData.forEach(element => {
              
              try {
                var hminone = element.endPeriod
                if(element.endPeriod){
                  hminone = moment(element.endPeriod,'YYYY-MM-DD').format('YYYY-MM-DD')
                }
              } catch (error) {
                // console.log('tgl error', error)
              }
              var colo = Math.floor(Math.random() * (vm.colors.length -1))
              var akun = { typemode: '', kode: '', totalpaidall:0, persenrealisasi:0, plannedamt:0, thismonthpay: 0, val: 0, tgl: hminone, uraian: '', bgcol: vm.colors[colo]}

              // Beban
              if(element['type'] == '4836.EAB.002' || element['type'] == '4836.EAC.002' || element['type'] == '4836.EAC.004'
                || element['type'] == '4836.EAD.003' || element['type'] == '4836.EAD.004' || element['type'] == '4836.EAN.002'
                || element['type'] == '4837.EAF.001' || element['type'] == '4837.EAF.004' || element['type'] == '4837.EAH.001'
                || element['type'] == '4838.EAK.003' || element['type'] == '4839.FAB.003'){
                // Belanja  Dukungan Manajemen
                
                akun['typemode'] = '4837.EAF.001.001.A'
                akun['kode'] = '525111'
                akun['bgcol'] = 'grey'

                let tmp = arrAkunBeban.find(x => x.typemode == '4837.EAF.001.001.A')
                if(tmp){
                  tmp['val'] += parseFloat(element['totalpaidall'])
                  tmp['plannedamt'] += parseFloat(element['plannedamt'])
                  tmp['thismonthpay'] += parseFloat(element['thismonthpay'])
                  tmp['persenrealisasi'] += parseFloat(element['persenrealisasi'])
  
                }else{
                  akun['val'] = parseFloat(element['totalpaidall'])
                  akun['plannedamt'] = parseFloat(element['plannedamt'])
                  akun['thismonthpay'] = parseFloat(element['thismonthpay'])
                  
                  akun['uraian'] = 'Dukungan Manajemen'
                  arrAkunBeban.push(akun)
                }

              }else if(element['type'] == '4712.FAM.001'){
                // Belanja Penghimpunan dan Pengelolaan Dana
                akun['typemode'] = '4712.FAM.001'
                akun['kode'] = '525117'
                akun['bgcol'] = 'green'

                let tmp = arrAkunBeban.find(x => x.typemode == '4712.FAM.001')
                if(tmp){
                  tmp['val'] += parseFloat(element['totalpaidall'])
                  tmp['plannedamt'] += parseFloat(element['plannedamt'])
                  tmp['thismonthpay'] += parseFloat(element['thismonthpay'])
                  tmp['persenrealisasi'] += parseFloat(element['persenrealisasi'])

                }else{
                  akun['val'] = parseFloat(element['totalpaidall'])
                  akun['plannedamt'] = parseFloat(element['plannedamt'])
                  akun['thismonthpay'] = parseFloat(element['thismonthpay'])
                  
                  akun['uraian'] = 'Pelaksanaan Penghimpunan dan Pengelolaan Dana'
                  arrAkunBeban.push(akun)
                }

              }else if(element['type'] == '4712.FAM.003'){
                // Belanja Riset

                akun['kode'] = '525117'
                akun['bgcol'] = 'blue'

                akun['val'] = parseFloat(element['totalpaidall'])
                akun['plannedamt'] = parseFloat(element['plannedamt'])
                akun['thismonthpay'] = parseFloat(element['thismonthpay'])
                
                akun['uraian'] = 'Penyaluran Dana Riset'
                arrAkunBeban.push(akun)

              }else if(element['type'] == '4712.FAM.007'){
                // Belanja Sarpras

                akun['kode'] = '525117'
                akun['bgcol'] = 'yellow'

                akun['val'] = parseFloat(element['totalpaidall'])
                akun['plannedamt'] = parseFloat(element['plannedamt'])
                akun['thismonthpay'] = parseFloat(element['thismonthpay'])
                
                akun['uraian'] = 'Penyaluran Sarana dan Prasarana'
                arrAkunBeban.push(akun)

              }else if(element['type'] == '4712.FAM.004'){
                // Belanja Kemitraan
                
                akun['kode'] = '525117'
                akun['bgcol'] = 'light-green'

                akun['val'] = parseFloat(element['totalpaidall'])
                akun['plannedamt'] = parseFloat(element['plannedamt'])
                akun['thismonthpay'] = parseFloat(element['thismonthpay'])
                
                akun['uraian'] = 'Penyaluran Kemitraan'
                arrAkunBeban.push(akun)

              }else if(element['type'] == '4712.FAM.002'){
                // Belanja Biodiesel
                akun['kode'] = '525117'
                akun['bgcol'] = 'orange'

                akun['val'] = parseFloat(element['totalpaidall'])
                akun['plannedamt'] = parseFloat(element['plannedamt'])
                akun['thismonthpay'] = parseFloat(element['thismonthpay'])

                akun['uraian'] = 'Pelaksanaan Program Biodiesel'
                arrAkunBeban.push(akun)

              }else if(element['type'] == '4712.FAM.005'){
                // Belanja  Peremajaan
                akun['kode'] = '525117'
                akun['bgcol'] = 'red'

                akun['val'] = parseFloat(element['totalpaidall'])
                akun['plannedamt'] = parseFloat(element['plannedamt'])
                akun['thismonthpay'] = parseFloat(element['thismonthpay'])

                akun['uraian'] = 'Pelaksanaan Peremajaan Kebun Kelapa Sawit'
                arrAkunBeban.push(akun)

              }else if(element['type'] == '4712.FAM.006'){
                // Belanja  SDM
                akun['kode'] = '525117'
                akun['bgcol'] = 'purple'
                
                akun['val'] = parseFloat(element['totalpaidall'])
                akun['plannedamt'] = parseFloat(element['plannedamt'])
                akun['thismonthpay'] = parseFloat(element['thismonthpay'])

                akun['uraian'] = 'Pelaksanaan Pengembangan SDM'
                arrAkunBeban.push(akun)

              }

            });

            arrAkunBeban.forEach(element => {
              if(element['plannedamt'] > 0){
                var a = parseFloat(element['val'])
                var b = parseFloat(element['plannedamt'])
                element['persenrealisasi'] = (a/b)
                console.log('persenrealisasi', element['persenrealisasi'])
              }
            });
            // console.log('arrAkunBeban', arrAkunBeban)

            objData.forEach(element => {
              
              try {
                var hminone = element.endPeriod
                if(element.endPeriod){
                  hminone = moment(element.endPeriod,'YYYY-MM-DD').format('YYYY-MM-DD')
                }
              } catch (error) {
                // console.log('tgl error', error)
              }
              var colo = Math.floor(Math.random() * vm.colors.length)
              var akun = { typemode: '', kode: '', totalpaidall:0, persenrealisasi:0, plannedamt:0, thismonthpay: 0, val: 0, tgl: hminone, uraian: '', bgcol: vm.colors[colo]}

              // Pendapatan
              if(element['type'] == 'C.10.1.1'){
                // Pendapatan Pungutan Dana Perkebunan Kelapa Sawit
                akun['kode'] = '424138'
                akun['plannedamt'] = '-'
                let tmp = arrAkunPenerimaan.find(x => x.kode == '424138')
                if(tmp){
                  tmp['val'] = parseFloat(tmp['val']) + parseFloat(element['fixamount'])
                  tmp['uraian'] = tmp['uraian'] + '<br> ' + element['uraian']
                }else{
                  akun['val'] = parseFloat(element['fixamount'])
                  akun['uraian'] = element['uraian']
                  arrAkunPenerimaan.push(akun)
                }
             
              }else if(element['type'] == 'C.10.1.3'){
                // Pendapatan Bunga Deposito
                akun['kode'] = '424911'
                akun['plannedamt'] = '-'
                let tmp = arrAkunPenerimaan.find(x => x.kode == '424911')
                if(tmp){
                  tmp['val'] = parseFloat(tmp['val']) + parseFloat(element['fixamount'])
                  tmp['uraian'] = tmp['uraian'] + '<br> ' + element['uraian']
                }else{
                  akun['val'] = parseFloat(element['fixamount'])
                  akun['uraian'] = element['uraian']
                  arrAkunPenerimaan.push(akun)
                }
       
              }else if(element['type'] == 'C.10.1.5' ){
                // Pendapatan Lain-lain
                akun['kode'] = '424919'
                akun['plannedamt'] = '-'
                let tmp = arrAkunPenerimaan.find(x => x.kode == '424919')
                if(tmp){
                  tmp['val'] = parseFloat(tmp['val']) + parseFloat(element['fixamount'])
                  tmp['uraian'] = tmp['uraian'] + '<br> ' + element['uraian']
                }else{
                  akun['val'] = parseFloat(element['fixamount'])
                  akun['uraian'] = element['uraian']
                  arrAkunPenerimaan.push(akun)
                }

              }else if(element['type'] == 'C.10.1.13'){
                // Pendapatan Usaha lain
                akun['kode'] = '424915'
                akun['plannedamt'] = '-'
                let tmp = arrAkunPenerimaan.find(x => x.kode == '424915')
                if(tmp){
                  tmp['val'] = parseFloat(tmp['val']) + parseFloat(element['fixamount'])
                  tmp['uraian'] = tmp['uraian'] + '<br> ' + element['uraian']
                }else{
                  akun['val'] = parseFloat(element['fixamount'])
                  akun['uraian'] = element['uraian']
                  arrAkunPenerimaan.push(akun)
                } 
              }
            });
          }
          
          vm.dataListLaporanBeban = arrAkunBeban
          console.log('arrAkunBeban', arrAkunBeban)

          vm.dataListLaporanPenerimaan = arrAkunPenerimaan
          console.log('arrAkunPenerimaan', arrAkunPenerimaan)
     
          vm.listLoading = false
        }).catch(err => { 
          // console.log(err)
          vm.listLoading = false
        })
        
      },
  }
}

</script>

<style>
.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
