<template>
  <div>
   <ul>
      <li class="py-2" v-for="(item,i) in listNews" v-bind:key="i">
        <a class="blue--text" :href="item.link" target="_blank">{{ item.title }}</a>
      </li>
    </ul>
        
  </div>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import moment from 'moment'
import { getRSSBPDPWebsite } from '@/api/admin'

export default {
  name: 'rssWebsite',
  data () {
    return {
        isLoading: false,
        currrole: [],
        listNews: [],
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
      return sdate
    },
    formatDatetime: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD/MM/YYYY HH:mm')
      return sdate
    }
  },
  created () {
    const vm = this
    // console.log('created getWebData')
    vm.getWebData()
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    // console.log('mount getWebData')
    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)
  },
  methods: {
    getWebData (){
      const vm = this

      vm.isLoading = true
      getRSSBPDPWebsite().then(response => {
        var data = response.data
        vm.listNews = data
        // console.log('RSSWeb', data)

        setTimeout(() => vm.getWebData(), 60000 * 60) // per Jam
        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
  }
}

</script>

<style>
.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
